iframe {
  width: 1px;
  min-width: 100% !important;
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

:focus::-webkit-input-placeholder {
  color: transparent !important;
}

:focus::-moz-placeholder {
  color: transparent !important;
}

:focus:-moz-placeholder {
  color: transparent !important;
}

:focus:-ms-input-placeholder {
  color: transparent !important;
}

.container-fluid--contain {
  max-width: 1140px;
}

.text-shadow {
  text-shadow: -2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white;
}

.text-shadow--bg-secondary {
  text-shadow: -2px -2px #616a9b, -2px 2px #616a9b, 2px -2px #616a9b, 2px 2px #616a9b;
}

.underline {
  background-size: 1px 1em;
  box-shadow: inset 0 -0.075em white, inset 0 -0.12em currentColor;
  display: inline;
}

.underline--bg-secondary {
  box-shadow: inset 0 -0.075em #616a9b, inset 0 -0.12em currentColor;
}

.order-sm-1,
.order-sm-2 {
  order: inherit !important;
}

@media (max-width: 575px) {
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
}

.row--margin-sm {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.col--margin-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.react-tabs__tab-panel--selected {
  height: 100%;
}

.react-tabs__tab-list {
  list-style-type: none;
  padding: 0;
}

.react-tabs__tab {
  float: left;
}

@media (max-width: 575px) {
  .container--xs-full.container, .container--xs-full.container-fluid,
  .container--xs-full .row {
    height: 100%;
  }
  .card--xs-full {
    min-height: 100vh;
    border-radius: 0 !important;
  }
}

.card .card--border-dark {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.rw-input {
  color: #495057 !important;
  padding: 0.9rem !important;
}

.rw-widget,
.rw-cell,
.rw-btn {
  color: #495057;
}

.rw-calendar.rw-popup {
  padding: 0 0.5rem 0.5rem 0.5rem !important;
}

.rw-cell.rw-state-disabled {
  color: #ced4da !important;
}

.rw-widget-container,
.rw-widget > .rw-widget-container {
  border: #dee2e6 1px solid !important;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 transparent, 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05) !important;
}

.rw-state-focus > .rw-widget-container,
.rw-state-focus > .rw-widget-container:hover {
  border: #ced4da 1px solid !important;
  outline: none !important;
  box-shadow: inset 0 0 0 transparent, 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05) !important;
}

.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover,
.rw-cell.rw-state-selected,
.rw-cell.rw-state-selected:hover {
  background-color: #00bf8f !important;
  border: #00bf8f 1px solid !important;
}

.rw-select-bordered {
  border-left: transparent 1px solid !important;
  box-shadow: inset 0 0 0 transparent !important;
}

.rw-widget-input,
.rw-filter-input {
  box-shadow: none !important;
}

.rw-i {
  color: #818a92 !important;
}

.border--none {
  border: none;
}

.padding--none {
  padding: 0;
}

.outline--none {
  outline: none;
}

.bg--transparent {
  background-color: transparent;
}

.col-form-label {
  white-space: nowrap;
}
