body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: env(safe-area-inset-top);
  width: 100vw;
}

/* .MapSquare::after{
  content: "";
  display: block;
  padding-bottom: 100%;
} */
