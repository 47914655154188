.notification-container {
  position: absolute;
  width: 100%;
  z-index: 101;
  top: 0;
  padding: 10px;
}

.notification-enter {
  opacity: 0.01;
}

.notification-enter.notification-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.notification-exit {
  opacity: 1;
}

.notification-exit.notification-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
